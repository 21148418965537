const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Booking Calendar',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.booking-calendar'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-calendar-event-line'
  },
  {
    title: 'Booking List',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.booking-list'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-book-mark-line'
  },
  {
    title: 'Maintenances',
    name: 'sidebar.admin.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Companies',
        is_active: false,
        link: {
          name: 'admin.companies'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Organizations',
        is_active: false,
        link: {
          name: 'admin.organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'User Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Employee Accounts',
        is_active: false,
        link: {
          name: 'admin.employee-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Venues',
        is_active: false,
        link: {
          name: 'admin.venues'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Items',
        is_active: false,
        link: {
          name: 'admin.items'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Mailing Lists',
        is_active: false,
        link: {
          name: 'admin.mailing-lists'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-mail-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.admin.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Booking Histories',
        is_active: false,
        link: {
          name: 'admin.booking-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Report by items',
        is_active: false,
        link: {
          name: 'admin.report-by-items'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Report by users',
        is_active: false,
        link: {
          name: 'admin.report-by-users'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default AdminSideMenu
