const UserSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Booking Calendar',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.booking-calendar'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-calendar-event-line'
  },
  {
    title: 'My Bookings',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.my-bookings'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-word-line'
  },
  {
    title: 'Booking Histories',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.booking-histories'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-chart-line'
  }
]

export default UserSideMenu
